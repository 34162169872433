import { Form, Input, Modal } from 'antd';
import { useState } from 'react';
import CategoriesService from '../../../services/categories';
import { Error } from '../../../components/error/Error';

type Props = {
  open: boolean;
  onClose: () => void;
  subcategory_id: string;
};

export const CreateExtraFilter = ({ open, onClose, subcategory_id }: Props) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  const handleCreate = (body: any) => {
    setConfirmLoading(true);
    CategoriesService.createCategoryCharacteristics(body)
      .then((res) => {
        if (res?.data?.id) {
          CategoriesService.attachCategoryCharacteristics({
            subcategory_id: subcategory_id,
            characteristics_id: res?.data?.id,
          }).then((res) => {
            onClose();
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.name_val?.[0]);
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const onFinish = (values: Record<string, string>) => {
    handleCreate(values);
  };

  return (
    <Modal
      forceRender
      title="Add extra filter"
      open={open}
      onOk={form.submit}
      onCancel={onClose}
      confirmLoading={confirmLoading}
    >
      <Form form={form} onFinish={onFinish} initialValues={null}>
        <Form.Item name="label" label="Label" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        <Form.Item name="name_val" label="Name value" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        <Form.Item name="filter_url" label="Filter URL" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        {error && <Error textError={error} />}
      </Form>
    </Modal>
  );
};
