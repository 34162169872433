import axios, { AxiosInstance } from 'axios';
import AuthService, { AuthStatus } from './auth';

const createAxiosClient = (baseURL: string): AxiosInstance => {
  const defaultOptions = {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {};
    }
    const token = localStorage.getItem('token');
    // const category = localStorage.getItem('category');
    // const user = JSON.parse(localStorage.getItem('user'));
    // const userFirstCategory = user?.categories?.[0]?.id || 1;

    config.headers.Authorization = token ? `Bearer ${token}` : '';

    config.params = {
      ...config.params,
      // category: category ?? userFirstCategory,
      // category_id: category ?? userFirstCategory,
    };

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        AuthService.getInstance.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const fetchClient = createAxiosClient(process.env.REACT_APP_BASE_URL!);
export const reportsApiClient = createAxiosClient(process.env.REACT_APP_BASE_REPORTS_URL!);
